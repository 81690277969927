// src/components/HomePage.js
import React, { useState, useEffect, useRef } from 'react'; 
import './HomePage.css';

function HomePage() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '' });
  const popcornRef = useRef(null); 

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://formspree.io/f/mrbggrby', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Thank you for subscribing!');
        setFormData({ name: '', email: '' });
      } else {
        alert('There was an issue submitting your information. Please try again.');
      }
    } catch (error) {
      console.error('Form submission error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (popcornRef.current) {
        popcornRef.current.style.left = `${event.pageX}px`;
        popcornRef.current.style.top = `${event.pageY}px`;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const parallaxImages = document.querySelectorAll('.parallax-image');
      parallaxImages.forEach((image) => {
        const scrollTop = window.pageYOffset;
        const rate = scrollTop * 0.08;
        image.style.transform = `translateY(${rate}px)`;
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="home-page">
      {/* Popcorn emoji that follows the mouse */}
      <div className="popcorn-emoji" ref={popcornRef}>🍿</div>

      <header className="header">
        <a href="#top" className="logo">MyDen</a> {/* Added link to top */}
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <nav className={`nav-menu ${menuOpen ? 'open' : ''}`}>
          <a href="#about">About</a>
          <a href="#showcase">Showcase</a>
          <a href="#create-share">Create & Share</a>
          <a href="#signup">Sign Up</a>
          <a href="#contact">Contact</a>
        </nav>
      </header>

      {/* Section 1: Hero Section */}
      <section className="hero-section" id="top">
        <div className="hero-container">
          <div className="video-frame">
            <video className="hero-video" autoPlay loop muted playsInline preload="metadata" loading="lazy">
              <source src="/videos/myden-hero1.mp4" type="video/mp4" />
            </video>
            <div className="hero-content">
              <h1>MyDen</h1>
            </div>
          </div>
        </div>
      </section>



      {/* Section 2: About Section */}
      <section className="about-section" id="about">
        <div className="about-content">
          <h2>Moments over Metrics</h2>
          <p>MyDen is a social media platform; build your own den, invite friends, and view together.</p>
        </div>
      </section>

      {/* Section 3: Showcase Section */}
      <section className="showcase-section" id="showcase">
        <h2>Showcase</h2>
        <p>MyDen is a social media platform; build your own den, invite friends, and view together.</p>
        <div className="showcase-container">
          <div className="video-item">
            <video autoPlay loop muted>
              <source src="/videos/videoaifa1.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="video-item">
            <video autoPlay loop muted>
              <source src="/videos/videoaifs2.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="video-item">
            <video autoPlay loop muted>
              <source src="/videos/videoaifa3.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="video-item">
            <video autoPlay loop muted>
              <source src="/videos/videiaifa4.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      {/* Section 4: Create and Share Section with Parallax Images */}
      <section className="create-share-section" id="create-share">
        <h2>Create Your Dream Den and Invite Friends</h2>
        <p>Make your space truly yours and share it with those who matter the most.</p>
        <div className="parallax-container">
          <div className="parallax-image" style={{ backgroundImage: 'url(/images/aifaimage1.jpg)' }}></div>
        </div>
      </section>

      {/* Section 5: Sign Up Section */}
      <section className="signup-section" id="signup">
        <h2>Get Ready to:</h2>
        <div className="signup-container">
          <div className="signup-item">
            <h3>Sign Up</h3>
            <p>Join our mailing list to be the first to test our app.</p>
          </div>
          <div className="signup-item">
            <h3>Share</h3>
            <p>Tell your friends to get ready for discounts and offers for first sign ups!</p>
          </div>
          <div className="signup-item">
            <h3>Watch</h3>
            <p>Watch your favourite shows and movies in your own curated space.</p>
          </div>
          <div className="signup-item">
            <h3>Stream</h3>
            <p>Stream content live in your very own den.</p>
          </div>
        </div>
      </section>

      {/* Section 6: Background Section */}
      <section className="background-section">
        <div className="background-content">
          <h2>Create and Share with Ease</h2>
          <p>Enjoy a beautiful environment with stunning visuals, and make each den unique with customizable options.</p>
        </div>
      </section>

   {/* Section 7: Contact Section */}
<section className="contact-section" id="contact">
  <div className="contact-box">
    <h2>Get In Touch</h2>
    <p className="contact-email">aifa@aifilm.academy</p>
    <div className="contact-columns">
      <div className="footer-column">
        <h3>Company</h3>
        <ul>
          <li><a href="#about">About</a></li>
          <li><a href="#showcase">Showcase</a></li>
          <li><a href="#create-share">Create & Share</a></li>
          <li><a href="#signup">Sign Up</a></li>
        </ul>
      </div>
      <div className="footer-column">
        <h3>Social</h3>
        <ul>
          <li><a href="https://www.tiktok.com/@_my_den_" target="_blank" rel="noopener noreferrer">TikTok</a></li>
        </ul>
      </div>
      <div className="footer-column">
        <h3>Stay In Touch</h3>
        <p>Subscribe to stay up to date with our latest thinking. You wouldn't want to miss out.</p>
        <form className="contact-form">
          <input type="email" placeholder="Email" />
          <button type="submit"><span className="arrow">→</span></button>
        </form>
      </div>
    </div>
  </div>
</section>


      {/* Footer */}
      <footer>
        <p>&copy; 2024 MyDen. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default HomePage;
